import type { PageComponentType } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { LazyConfig } from '@/features/a-dynamic-page/components/page-component-registry-type';

import React from 'react';
import { useInView } from 'react-intersection-observer';

import { IS_NODE_ENV_DEV } from '@/constants/env';
import { useForceRenderPageComponentIndex } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-component-index';
import { useClientUrl } from '@/core/features/app/app-atoms';
import { savePageComponentIndex } from '@/core/features/scroll/page-scroll-restoration/page-scroll-position-service';

type PageComponentLazyWrapperProps = {
    componentId: string;
    componentType: PageComponentType;
    lazyConfig: LazyConfig;
    pageComponentIndex: number;
    setLazyWrapperInView?: (state: boolean) => void;
};

export default function PageComponentLazyWrapper(props: React.PropsWithChildren<PageComponentLazyWrapperProps>) {
    const { children, componentId, componentType, lazyConfig, pageComponentIndex, setLazyWrapperInView } = props;

    const [clientUrl] = useClientUrl();
    const [minHeightState, setMinHeightState] = React.useState<null | number>(lazyConfig.minHeight);
    const wasInView = React.useRef(false);
    const lazyElementRef = React.useRef<HTMLDivElement | null>(null);

    const [forceRenderedIndex] = useForceRenderPageComponentIndex();

    const { inView, ref: inViewRef } = useInView({
        initialInView: lazyConfig.type === 'eager',
        rootMargin: '1600px',
        triggerOnce: true,
    });

    const isForceRendered = pageComponentIndex <= forceRenderedIndex;

    const show = inView || isForceRendered;

    // combine refs
    // https://www.npmjs.com/package/react-intersection-observer/v/8.33.0#user-content-how-can-i-assign-multiple-refs-to-a-component
    const setRefs = React.useCallback(
        (node: HTMLDivElement) => {
            // Ref's from useRef needs to have the node assigned to `current`
            lazyElementRef.current = node;
            // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
            inViewRef(node);
        },
        [inViewRef],
    );

    React.useEffect(() => {
        setLazyWrapperInView?.(inView);
    }, [inView, setLazyWrapperInView]);

    React.useEffect(() => {
        if (wasInView.current === show) {
            return;
        }
        wasInView.current = show;
    }, [show]);

    const updateSizes = React.useCallback(() => {
        if (!show) {
            return;
        }

        if (!lazyElementRef.current) {
            return;
        }

        const renderedElement = lazyElementRef.current.children[0];

        if (!renderedElement) {
            return;
        }

        const { height: updatedMinHeight } = renderedElement?.getBoundingClientRect() ?? {};

        if (updatedMinHeight === undefined) {
            return;
        }

        if (minHeightState !== updatedMinHeight) {
            if (lazyConfig.type === 'lazy' && lazyConfig.forceMinHeight) {
                setMinHeightState(Math.max(updatedMinHeight, minHeightState ?? 0));
            } else {
                setMinHeightState(Math.min(updatedMinHeight, 5000));
            }
        }
    }, [show, minHeightState, lazyConfig]);

    React.useEffect(() => {
        if (!show) {
            return;
        }
        savePageComponentIndex(clientUrl, pageComponentIndex);
    }, [show, clientUrl, pageComponentIndex]);
    React.useEffect(() => {
        if (!show) {
            return;
        }
        if ((lazyConfig.type === 'lazy' && lazyConfig.disableSizeUpdate) || lazyConfig.disableLazyContainer) {
            return;
        }
        const timeout = setTimeout(() => {
            if (show) {
                updateSizes();
            }
        }, 1000); // give it enough time to render everything

        return () => {
            clearTimeout(timeout);
        };
    }, [show, updateSizes, lazyConfig]);

    if (show && lazyConfig.disableLazyContainer) {
        return <>{children}</>;
    }

    return (
        <div
            className={'width-100 lazy-wrapper'}
            data-component-type={IS_NODE_ENV_DEV ? componentType : undefined}
            id={`c_${componentId}`}
            ref={setRefs}
            style={{
                minHeight: minHeightState ? `${minHeightState}px` : undefined,
                scrollMarginTop:
                    lazyConfig.type === 'lazy' && lazyConfig.scrollMarginTop
                        ? `${lazyConfig.scrollMarginTop}px`
                        : undefined,
            }}
        >
            {show && children}
            <style jsx>{`
                .lazy-wrapper:empty {
                    display: ${minHeightState === 0 && lazyConfig.type === 'lazy' && show ? 'none' : 'block'};
                }
            `}</style>
        </div>
    );
}
